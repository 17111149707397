<template>
  <div class="container">
    <div class="section section__left">
      <div>
        <span class="title">
          <h2>Bienvenido a Elha</h2>
        </span>
        <span class="separator"></span>
        <p>
          Te presentamos nuestra plataforma Valsalary. Una plataforma de mucho análisis dónde podrás
          identificar información importante para tu organización. Con nuestra plataforma podrás
          trabajar la brecha salarial y la equidad de género, además identificar las bandas
          salariales en todos los puestos. Descubre más con Valsalary.
        </p>
        <svg viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <circle cx="60" cy="60" r="50" fill="white" />
          <circle cx="60" cy="60" r="30" />
        </svg>
      </div>
    </div>
    <ValidationObserver
      ref="form"
      tag="div"
      v-slot="{ handleSubmit }"
      class="section section__right"
    >
      <form @submit.prevent="handleSubmit(handleLogin)" class="form-container">
        <logo size="sm" />

        <span class="title">
          <h2>Signin</h2>
        </span>

        <span class="separator"></span>

        <span>
          <custom-input
            v-model="email"
            vid="email"
            id="email"
            type="email"
            label="Correo"
            rules="required"
          />
          <custom-input
            v-model="password"
            vid="password"
            id="password"
            type="password"
            label="Contraseña"
            rules="required"
          />
          <div class="social-media__logos">
            <a href="https://www.facebook.com/profile.php?id=100076061235857"
              ><img src="../assets/facebook-logo.png" alt=""
            /></a>
            <a href="https://www.instagram.com/elhadigital/"
              ><img src="../assets/instagram-logo.png" alt=""
            /></a>
            <a href="https://www.linkedin.com/company/elhadigital/"
              ><img src="../assets/linkedin-logo.png" alt=""
            /></a>
          </div>

          <Button type="submit" variant="primary" :disabled="loading">
            {{ loading ? 'Cargando...' : 'Iniciar Sesión' }}
          </Button>
        </span>
      </form>

      <svg viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <rect width="70" height="70" rx="15" ry="15" />
        <rect width="45" height="45" fill="white" x="14" y="12" rx="10" ry="10" />
      </svg>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';

import Button from '../components/buttons/Button.vue';
import CustomInput from '../components/custom/CustomInput.vue';
import Logo from '../components/Logo.vue';

export default {
  name: 'Login',

  components: {
    Button,
    CustomInput,
    ValidationObserver,
    Logo,
  },

  data() {
    return {
      email: '',
      password: '',
      loading: false,
    };
  },

  computed: {
    ...mapState(['userProfile']),
  },

  methods: {
    ...mapActions(['login']),

    handleLogin() {
      this.loading = true;

      this.login({
        email: `${this.email}`,
        password: this.password,
      }).catch((e) => {
        console.log(e);
        this.loading = false;
        this.setErrorMessage(e);
      });
    },

    setErrorMessage(error) {
      console.log(error);
      switch (error.code) {
        case 'auth/wrong-password':
          this.$refs.form.setErrors({
            password: ['La contraseña ingresada es incorrecta.'],
          });
          break;

        case 'auth/user-not-found':
          this.$refs.form.setErrors({
            email: ['El usuario ingresado no fue encontrado.'],
          });
          break;

        case 'auth/too-many-requests':
          this.$refs.form.setErrors({
            password: [
              'Se han producido demasiados intentos fallidos, por favor inténtelo nuevamente más tarde.',
            ],
          });
          break;

        default:
          this.$refs.form.setErrors({
            password: ['Ocurrio un error, por favor inténtalo de nuevo.'],
          });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  .section {
    width: 50%;
    &__left {
      background-color: var(--main-color-500);
    }
  }
}
.section__right {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  svg {
    z-index: -1000;
    position: absolute;
    top: 18%;
    right: -10.5rem;
    height: 20rem;
    transform: rotate(40deg);
    rect:first-child {
      fill: var(--gray-color-100);
    }
  }
}
.section__left {
  background-image: url('../assets/login.jpg');
  // background-size: contain;
  // background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  svg {
    position: absolute;
    right: -7.5rem;
    top: 65%;
    height: 15rem;
    circle:nth-child(2) {
      fill: var(--main-color-500);
    }
  }
  & > div {
    margin: auto;
    width: 350px;
    display: flex;
    flex-flow: column;
  }

  .title {
    h2 {
      color: white;
    }
  }
  p {
    color: white;
  }
  .separator {
    background-color: white;
    width: 5rem;
    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
}

.logo {
  position: absolute;
  right: 1rem;
}

.form-container {
  position: relative;
  max-width: 350px;
  width: 90%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem;
  margin: 0 auto;
  border-radius: 5px;

  .title {
    gap: 0.4rem;
    h2 {
      text-align: center;
    }
    p {
      font-weight: var(--medium);
      color: var(--font-color-200);
      line-height: 1.25;
      font-size: 0.8rem;
    }

    margin-top: 1rem;
  }

  span {
    display: flex;
    flex-flow: column;
    gap: 1.2rem;
  }
}

.separator {
  height: 2.5px;
  width: 2rem;
  background-color: var(--main-color-500);
  margin: 0.5rem auto;
  margin-bottom: 2rem;
}

.social-media__logos {
  display: flex;
  gap: 1rem;
  justify-content: center;
  img {
    height: 1.75rem;
  }
}
</style>
